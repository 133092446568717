import React from "react"

import subHeaderStyles from './subheader.module.scss'

const subHeader = () => {

    var ReactRotatingText = require('react-rotating-text');

    return (
            <p className={subHeaderStyles.tagline}>A collection of
            <ReactRotatingText className={subHeaderStyles.rotatingText} items={['thoughts', 'tweets', 'articles', 'excerpts', 'videos', 'templates']} typingInterval={150} />
            <br />to help you learn product management</p>
    )
}

export default subHeader