import React from "react"

import SkillListItem from './SkillListItem'
import skillListStyles from './skilllist.module.scss'


const SkillList = (props) => {
    return (
        <div className="skill-list-container">
            <h2 className="section-title">{props.type}</h2>
            <ul className={skillListStyles.list}>    
                <SkillListItem type={props.type} skill={props.skill}/>         
            </ul>
        </div>
    )
}

export default SkillList