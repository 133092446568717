import React from "react"
import Layout from "../components/Layout"
import SEO from '../components/seo'
import SkillList from "../components/SkillList"
import GardenKey from "../components/GardenKey"
import RecentList from "../components/RecentList"

const IndexPage = () => {
  
  return (
    <Layout>
      <SEO
         title="Product Skills"
      />
      <div className="skill-list section-container">
        <SkillList type="core skills"/>
        <div className="hide-desktop"><GardenKey/></div>
        <SkillList type="advanced skills"/>
      </div>
      <div className="hide-mobile"><GardenKey/></div>
      <RecentList/>
    </Layout>
  )
}

export default IndexPage