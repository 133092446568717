import React from "react"

import gardenKeyStyles from './gardenkey.module.scss'

const gardenKey = () => {
    return (
        <div >
            <div className={gardenKeyStyles.container}>
                <p className={gardenKeyStyles.text}>Each skill contains sections that are at various stages of development</p>
                <div className={gardenKeyStyles.key}>
                <ul>
                <li><span role="img" aria-label="tree">🌳</span>  <span className={gardenKeyStyles.stage}>Evergreen</span> – fully developed</li>
                <li><span role="img" aria-label="branch">🌿</span>  <span className={gardenKeyStyles.stage}>Sprouting</span> – growing and taking shape</li>
                <li><span role="img" aria-label="sprout">🌱</span>  <span className={gardenKeyStyles.stage}>Seedling</span> – just getting started</li>
                </ul>
                </div>
                <p className={gardenKeyStyles.text}>Each section will continuously grow based on ideas and feedback</p>
            </div>
        </div>

    )
}

export default gardenKey