import React from "react"

import Header from './Header'
import SubHeader from "./SubHeader"
import Footer from './Footer'
import '../styles/index.scss'
import layoutStyles from './layout.module.scss'

const Layout = (props) => {

  return (
    <div className={layoutStyles.container}>
        <div className={layoutStyles.content}>
            <Header>
              <SubHeader />
            </Header>
            {props.children}
        </div>
        <Footer/>
    </div>  
    

  )
}

export default Layout