import React from "react"
import { Link, graphql,useStaticQuery } from "gatsby"

import recentListStyles from './recentlist.module.scss'


const RecentList = (props) => {
    const data = useStaticQuery(graphql`
    query {
        allMdx (sort: {order: DESC, fields: [frontmatter___date]}){
          edges {
            node {
              frontmatter {
                subtitle
                title
                skill
                date(formatString: "MMM Do, YYYY")
                type
                index
                published
              }
              excerpt
              fields {
                slug
              }
              timeToRead
            }
          }
        }
      }
    `)

    return (
      <div className={recentListStyles.container}>
        <div className="section-container">
          <div className={recentListStyles.postsSection}>
            <h2 className="section-title">Latest Posts</h2>
            {data.allMdx.edges.map((edge) => {
              if (edge.node.frontmatter.type === "post" & edge.node.frontmatter.published === true) {
                return (
                <div className={recentListStyles.post} key={edge.node.frontmatter.title}>
                  <p className={recentListStyles.date}>{edge.node.frontmatter.date}</p>
                  <h2 className={recentListStyles.title}><Link className={recentListStyles.link} to={edge.node.fields.slug}>{edge.node.frontmatter.title}</Link></h2>
                  {edge.node.frontmatter.subtitle && <h3 className={recentListStyles.subtitle}>{edge.node.frontmatter.subtitle}</h3>}
                  <p className={recentListStyles.excerpt}>{edge.node.excerpt}</p>
                  <p className={recentListStyles.date}>· {edge.node.timeToRead} min read</p>
                  <Link className={recentListStyles.buttonLink} to={edge.node.fields.slug}>Read More</Link>
                </div>
                )
              }
              return null
            })}
          </div>  
        </div>
      </div>
    )
}

export default RecentList