import React from "react"
import { Link, graphql,useStaticQuery } from "gatsby"

import skillListStyles from './skilllist.module.scss'


const SkillItem = (props) => {
    const data = useStaticQuery(graphql`
    query {
        allMdx {
          edges {
            node {
              frontmatter {
                subtitle
                preview
                title
                skill
                date
                type
                stage
                index
                published
              }
              excerpt
              fields {
                slug
              }
            }
          }
        }
      }
    `)
    const linkArrow = (
      <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="12px" height="16px" viewBox="0 -15 30 100" xmlSpace="preserve">
          <polyline fill="none" stroke={"#03a103"} strokeWidth={15} strokeLinecap="round" strokeLinejoin="round" points="
    0.375,0.375 45.63,38.087 0.375,75.8 " />
        </svg>
    );
    return (
        <div>
            {data.allMdx.edges.sort((a,b) => a.node.frontmatter.index - b.node.frontmatter.index).map((edge) => {
            const isPublished = edge.node.frontmatter.published
            if (edge.node.frontmatter.type === props.type || edge.node.frontmatter.skill === props.skill) {
                return (
            <li className={skillListStyles.skillList} key={edge.node.frontmatter.title}>
                { isPublished 
                    ? <h2 className={skillListStyles.title}><Link to={edge.node.fields.slug}>{edge.node.frontmatter.title}</Link></h2>
                    : <h2 className={skillListStyles.title}>{edge.node.frontmatter.title}</h2>
                } 
                {edge.node.frontmatter.subtitle && 
                    <h3 className={skillListStyles.subtitle}>{edge.node.frontmatter.subtitle}</h3>
                }
                {edge.node.frontmatter.preview && 
                <h3 className={skillListStyles.preview}>{edge.node.frontmatter.preview}</h3>
                }
                <div className={skillListStyles.linkText}>
                    <span className={skillListStyles.growth}>{edge.node.frontmatter.stage}</span>
                    { isPublished 
                          ? <Link className={skillListStyles.link} to={edge.node.fields.slug}>
                              <div className={skillListStyles.linkButton}>Learn More <span className={skillListStyles.wiggle}>{linkArrow}</span></div>
                          </Link>
                          : <div className={skillListStyles.linkButton}><span>Coming Soon</span></div>
                    }    
                </div>
            </li>
            )
        }
        return null
        })}
                
        </div>
    )
}

export default SkillItem